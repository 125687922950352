.filter {
    font-size: 1rem;
    float: right;
    font-weight: normal;
    font-style: normal;

    .content-title & {
        line-height: 55px;
    }

    .on-mobiles({
        float: none;
        display: block;

        .content-title & {
            line-height: inherit;
        }
    });
}
