.interview-partner {
    color: @color-brand-primary;
    font-weight: bold;

    &:after {
        content: ": ";
    }
}

.interview-line {
    margin-top: -.825em;
}
