.btns {
    vertical-align: top;
    margin-top: 1em;
}

.btn {
    border: 2px solid transparent;
    border-radius: 2px;
    padding: 0.625em 1em;
    font-family: @font-family-headline;
    background: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    overflow: hidden;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: color .2s, transform .2s, border-color .2s;

    &:disabled {
        pointer-events: none;
    }
}

.btn-sm {
    font-size: 0.8em;
}

.btn-action(@bg-color, @color) {
    border-color: @bg-color;

    &:after {
        content: " ";
        left: -2px;
        right: -2px;
        bottom: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        transform: translate3d(0, 100%, 0);
        transition: transform .2s;
        background-color: @bg-color;
    }

    &:hover,
    &:focus,
    &:active {
        color: @color;

        &:after {
            transform: translate3d(0, 0, 0);
        }
    }
}

.btn-hop() {
    &:active {
        transform: translate3d(0, 5px, 0);
    }
}

.btn-link {
    .btn-action(#eaeaea, @color-brand-primary);
    .btn-hop();
}

.btn-default {
    .btn-action(@color-brand-primary, white);
    .btn-hop();
}

.btn-primary {
    .btn-action(@color-brand-primary, white);
    .btn-hop();
    background-color: @color-brand-primary;
    color: white;
}

.btn-ca {
    border-radius: 0 0 2px 2px;
    font-size: 0.85rem;
    padding: 0.325em 0.5em;
    border: none;

    &.btn-default {
        background-color: #f0f0f0;
    }
}

.btn-plain {
    .btn-hop();
    color: @color-brand-primary;
}

.btn-mute {
    .btn-hop();
    color: @color-brand-primary;
    background-color: #f0f0f0;
    border-color: #f0f0f0;

    &:hover,
    &:active,
    &:focus {
        background-color: #eaeaea;
        border-color: #eaeaea;
    }
}

.btn-social {
    display: flex;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 1rem;
    color: @color-brand-primary-complement;

    .on-mobiles({
        margin-bottom: 0;
    });

    &:hover,
    &:active {
        color: lighten(@color-brand-primary-complement, 10%);
    }
}
