@import "../definitions/mixins";

@page-meta-size: 15.625rem;

.page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .on-page-break({
        display: block;
        margin: 0 auto;
    })
}

.page-content {
    position: relative;
    z-index: 1;
    margin-right: 2rem;

    &:focus {
        outline: none;
    }

    .on-page-break({
        margin: 0;
    });

    @media print {
        margin: 0;
    }
}

.page-skip {
    position: absolute !important;
    overflow: hidden !important;
}

.page-start {
    position: absolute;
    top: 0; left: 0; right: 0;
    display: block;
}

.page-meta {
    width: @page-meta-size;

    .on-page-break({
        position: fixed;
        top: 100%;
        width: 100vw;
        transition: top .3s ease-in-out;
        margin-top: -40px;
        z-index: 1000;

        [data-priority="low"] {
            display: none;
        }

        .meta-box {
            border-radius: 0;
        }

        body:not(.article) & {
            display: none;
        }
    });

    .page-meta-state:checked ~ & {
        top: 100px;
    }
}

.page-meta-inner {
    z-index: 1;
    top: 0;

    @media(min-width: 980px) {
        position: relative;

        [data-priority-hide] {
            max-height: 100vh;
            transition: max-height 1s, margin .5s;
            overflow: hidden;
        }

        &.sticky-fixed {
            transition: top .3s ease-out;
            position: fixed;
            width: @page-meta-size;

            &[data-sticky-space-saving~="high"] [data-priority-hide="high"],
            &[data-sticky-space-saving~="medium"] [data-priority-hide="medium"],
            &[data-sticky-space-saving~="low"] [data-priority-hide="low"] {
                max-height: 0;
                margin: 0;
            }
        }
    }
}

.page-meta-state {
    display: none;
}

.page-meta-header {
    background-color: @color-text-default;
    color: white;
    height: 40px;
    line-height: 1;
    display: none;
    transition: transform .2s ease-out, opacity .2s ease-out, background-color .3s ease-in-out;

    label {
        padding: 0 2em;
    }

    h3,
    label,
    i {
        display: block;
        line-height: 40px;
        margin: 0;
    }

    .fa {
        transition: transform .3s ease-in-out;
        float: right;
    }

    .on-page-break({
        display: block;

        .headroom--unpinned & {
            transform: translateY(100%);
            opacity: 0;
        }

        .page-meta-state:checked ~ .page-meta & {
            background-color: @color-brand-primary;
            visibility: visible;
            transform: none;
            opacity: 1;

            .fa {
                transform: rotate(180deg);
            }
        }
    });
}

.page-meta-body {
    .on-page-break({
        // older ios devices don’t support calc with viewport units
        height: 100vh;
        height: calc(100vh - 100px);
        overflow: auto;
        background-color: white;
        display: flex;
        flex-wrap: wrap;

        > * {
            width: 50%;
        }

        .is-scrolling & {
            visibility: hidden;
        }

        .page-meta-state:checked ~ .page-meta & {
            visibility: visible;
        }
    });

    @media (max-width: @screen-xs-min) {
        display: block;

        > * {
            width: 100%;
        }
    }
}
