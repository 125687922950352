.teaser {
    position: relative;
    page-break-inside: avoid;
}

.teaser-link {
    text-decoration: none;
    color: @color-text-default;
    display: block;

    .preview-text {
        &:after {
            content: "Weiterlesen...";
            color: @color-brand-primary;
            text-decoration: underline;
        }
    }
}

.teaser-header {
    text-decoration: none;
    color: @color-text-default;
    font-family: @font-family-headline;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h4 {
        margin: 0;
        font-size: 1.5rem;
        padding-top: .2em;
        padding-bottom: .2em;
        line-height: 1.4;
    }
}

.teaser-body {
    margin-top: 0.575em;

    :first-child {
        margin-top: 0;
    }
}

.teaser-body-unspaced {
    padding-left: 0;
    padding-right: 0;
}

.teaser-footer {
    margin-top: 0.575em;
}

.teaser-meta {
    font-family: @font-family-headline;
    margin: 0.375em 0 0 0;
    break-inside: avoid;

    &:last-child {
        margin-top: 0;
    }

    dt {
        white-space: nowrap;
    }

    dd {
        &:after {
            white-space: pre;
            content: "  ";
        }

        &:last-child:after {
            content: "";
        }
    }

    dt, dd {
        display: inline;
        margin: 0;
        font-size: 0.8em;
    }
}

.teaser-issue {
    &:extend(.content-wrap all);
    background-color: @color-brand-primary-complement;
    padding: 1rem 3rem;
    box-sizing: border-box;
    margin-bottom: 2rem;
    border-radius: 2px;

    .teaser-link,
    .teaser-link .preview-text:after,
    .teaser-header {
        color: white;
    }

    .on-mobiles({
        .teaser-header {
            border-bottom: none;

            h4 {
                padding: 0;
            }
        }
    })
}
