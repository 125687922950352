.list-unstyled {
    margin: 0;
    padding: 0;

    > li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

.list-inline {
    display: inline;

    > li {
        display: inline;
    }
}

.list-separated-comma {
    > li {
        &:before {
            content: ", ";
        }

        &:last-child:before {
            content: " und ";
        }

        &:first-child:before {
            content: "";
        }
    }
}

.list-separated-bar {
    > li {
        &:before {
            content: " | ";
        }

        &:before:after {
            content: "";
        }
    }
}
