@logo-base-color: rgba(0, 0, 0, 0.8);
@logo-highlight-color: @color-brand-primary;

.logo {
    position: relative;
    margin-bottom: 0.5rem;
    margin-top: -13px;
    display: block;

    svg {
        width: 80%;
        height: auto;
        margin-left: 10%;
    }

    #circles,
    #bars,
    #font {
        fill-opacity: 1;
        fill: @logo-base-color;
    }

    #connectors {
        stroke-width: 1;
        stroke: @logo-base-color;
        stroke-opacity: 1;
    }

    #circles {
        circle {
            transition: fill .5s ease-in-out;

            &:hover {
                fill: @logo-highlight-color;
            }
        }
    }

    #font-second {
        fill: @logo-highlight-color;
    }
}

.logo-circle-blink {
    animation: 1s ease-in-out logo-blink;
}

.logo-net {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(60%, 75%) scale(4);
    opacity: 0.05;

    .on-mobiles({
        display: none;
    });
}

@keyframes logo-blink {
    from, to {
        fill: @logo-base-color;
    }
    50% {
        fill: @logo-highlight-color;
    }
}
