.dropdown-content {
    :first-child {
        margin-top: 0;
    }
}

.dropdown-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 1.3rem;
    transition: background-color .3s;


    &[data-dropdown-state] {
        cursor: pointer;
        padding: 0.1em 0;

        i {
            display: inline-block;
        }

        &:hover,
        &:focus {
            outline: none;

            i {
                animation: levitate 3s infinite;
            }
        }
    }

    &[data-dropdown-state="enabled"] i {
        transform: rotate(180deg);
    }

    .dropdown-content + & {
        margin-top: 1em;
    }

    i {
        font-size: 80%;
        transition: transform .3s;
        position: relative;
        display: none;
    }
}

.dropdown-content {
    transition: max-height ease-out;
    overflow: hidden;

    [data-dropdown-state="disabled"] + & {
        max-height: 0 !important;
    }
}

@keyframes levitate {
    from, to {
        top: 0;
    }
    33% {
        top: 5px;
    }
    50% {
        top: -3px;
    }
    66% {
        top: 5px;
    }
}
