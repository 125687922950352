@font-face{
    font-family: "Fira Sans";
    src: url("../fonts/FiraSans-Light.eot");
    src: url("../fonts/FiraSans-Light.eot") format("embedded-opentype"),
         url("../fonts/FiraSans-Light.woff2") format("woff2"),
         url("../fonts/FiraSans-Light.woff") format("woff"),
         url("../fonts/FiraSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: "Fira Sans";
    src: url("../fonts/FiraSans-Regular.eot");
    src: url("../fonts/FiraSans-Regular.eot") format("embedded-opentype"),
         url("../fonts/FiraSans-Regular.woff2") format("woff2"),
         url("../fonts/FiraSans-Regular.woff") format("woff"),
         url("../fonts/FiraSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: "Fira Sans";
    src: url("../fonts/FiraSans-SemiBold.eot");
    src: url("../fonts/FiraSans-SemiBold.eot") format("embedded-opentype"),
         url("../fonts/FiraSans-SemiBold.woff2") format("woff2"),
         url("../fonts/FiraSans-SemiBold.woff") format("woff"),
         url("../fonts/FiraSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face{
    font-family: "Fira Sans";
    src: url("../fonts/FiraSans-Bold.eot");
    src: url("../fonts/FiraSans-Bold.eot") format("embedded-opentype"),
         url("../fonts/FiraSans-Bold.woff2") format("woff2"),
         url("../fonts/FiraSans-Bold.woff") format("woff"),
         url("../fonts/FiraSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
