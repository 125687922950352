@import "list";
@import "../definitions/mixins";

.content-wrap {
    width: 42rem;
    max-width: 100vw;

    @media print {
        width: auto;
    }
}

.content {
    &:extend(.content-wrap all);
    padding: 0 0 3rem;
    position: relative;


    .on-mobiles({
        margin: 0 auto;
    });

    .content {
        page-break-before: always;
        width: auto;
    }
}

.content-header,
.content-body,
.content-footer {
    padding: 0 3rem;

    .content {
        margin: 0 -3rem;
    }

    .on-mobiles({
        padding: 0 1rem;

        .content {
            margin: 0 -1rem;
        }
    });
}

.content-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    color: @color-brand-primary;
    font-family: @font-family-headline;
    padding: 3rem 0 1em 0;
    margin: 0;

    small {
        font-size: 0.4em;
    }

    .on-mobiles({
        font-size: 2.4rem;
    })
}

.content-subtitle {
    display: block;
    font-size: 1.75rem;
    line-height: 1.22;
    color: @color-text-light;
    font-weight: 300;
    font-family: @font-family-headline;
    margin-top: .3em;
}

.content-header {
    background-color: white;
    border-radius: 2px 2px 0 0;
}

.content-alone {
    font-size: 1.1rem;
    letter-spacing: -.003em;
    border-radius: 0 0 2px 2px;

    > :first-child {
        margin-top: 0;
    }

    p,
    blockquote,
    ul, ol {
        margin-top: 1.825em;
        margin-bottom: 0;

        & + p,
        & + blockquote {
            margin-top: 1.3em;
        }
    }

    p + h3 {
        margin-top: 1.4em;
    }

    h3 {
        font-weight: 700;
        font-style: normal;
        font-size: 2rem;
        line-height: 1.15;
        letter-spacing: -.02em;
        margin-top: 1.6em;
        margin-bottom: 0;
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    h4 {
        font-weight: 600;
        font-style: normal;
        font-size: 1.3rem;
        line-height: 1.1;
        margin-top: 1em;
        margin-bottom: 0;
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    h5 {
        font-weight: 600;
        font-style: normal;
        font-size: 1rem;
        line-height: 1.1;
        margin-top: 1em;
        margin-bottom: 0;
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    h3 + * {
        margin-top: 0.575em;
    }

    h4 + * {
        margin-top: 0.375em;
    }

    h5 + * {
        margin-top: 0.375em;
    }

    blockquote {
        margin-left: 0;
        font-style: italic;
        page-break-inside: avoid;
        position: relative;

        &:after {
            content: " ";
            display: block;
            position: absolute;
            top: 0; bottom: 0; left: -1.15em;
            width: 3px;
            background-color: currentColor;
        }

        :first-child {
            margin-top: 0;
        }
    }

    table {
        position: relative;
        margin-left: -.6em;
        margin-right: -.6em;
        top: -.15em;

        th {
            text-align: left;
            font-weight: normal;
        }

        th, td {
            padding: .15em .6em;
        }
    }

    .on-mobiles({
        word-wrap: break-word;
        hyphens: auto;

        h3 {
            font-size: 1.7rem;
        }
    });
}

.content-body {
    &:extend(.content-alone all);
    background-color: white;
    padding-bottom: 2rem;
}

.content-actions {
    white-space: nowrap;
    position: absolute;
    right: 3rem;
    top: 0;

    .on-mobiles({
        right: 1rem;
    });
}

.content-list {
    &:extend(.list-unstyled all);

    > li {
        margin-top: 2em;

        &:first-child {
            margin-top: 0;
        }
    }

    h3 + & {
        margin-top: 0.575em;
    }
}

.content-list-inline {
    > li {
        display: inline-block;
        margin: 0 0.325em 0.325em 0;
        vertical-align: top;

        > a {
            &:extend(.btn all);
            &:extend(.btn-link all);
        }
    }
}

.content-license {
    .content .content & {
        display: none;
    }
}

.content-list-authors,
.content-list-categories,
.content-list-tags,
.content-list-timeslots,
.content-list-speakers {
    &:extend(.list-unstyled all);
    &:extend(.list-inline all);
    &:extend(.list-separated-comma all);
}

.book-abstract,
.citation {
    margin-top: 1em;
    page-break-inside: avoid;
}

.content-mute {
    font-size: .75rem;
    color: #777;
}
