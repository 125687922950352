.meta-box {
    font-family: @font-family-headline;
    border-radius: 2px;
    background-color: white;
    margin-bottom: 2rem;
    overflow: hidden;
}

.meta-box-header {
    text-align: center;
    padding: 0.425rem 1rem;
    background-color: @color-text-default;
    font-size: 1.1rem;
    color: white;
}

.meta-box-body {
    padding: 0.425rem 1rem;

    dl {
        text-align: center;
        margin: 0;

        dt, dd {
            margin: 0;
            padding: 0.1em 0.2em;
        }

        dt {
            width: 80%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.075);
            display: inline-block;
        }

        dd {
            margin-bottom: 0.6em;
            display: block;
            word-wrap: break-word;
        }
    }
}
