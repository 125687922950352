@import "../definitions/mixins";

body {
    margin-top: 80px;
}

.nav {
    text-align: center;
}

.nav-top {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10000;
    .material-shadow(1);


    .on-page-break({
        will-change: transform;
        transition: transform .2s ease-out, opacity .2s ease-out;

        &.headroom--unpinned:not(.headroom--top) {
            transform: translateY(-105%);
            opacity: 0;
        }
    });
}

.nav-wrap {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    z-index: 1;
    padding: 0 1em;

    nav {
        margin: 0 0 0 1em;
    }

    .on-mobiles({
        display: block;
        padding: 0.3em 1em;

        nav {
            margin: 0 0.75em 0 0;
        }
    })
}

.nav-inner {
    display: flex;
    align-items: center;

    .on-mobiles({
        justify-content: space-between;
    });
}

.nav-logo {
    color: @color-brand-primary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    margin: 0;

    a {
        text-decoration: none;
        transition: color .3s ease-in-out;
        line-height: 1;
        position: relative;
        top: 0.075em;
        display: block;

        &:hover,
        &:focus {
            outline: none;
            color: @color-text-default;
        }
    }

    .on-mobiles({
        order: 5;
        margin: 0 auto;
    });
}

.nav-menu {
    margin: 0;
    padding: 0;
    background-color: white;
    display: flex;
    align-items: stretch;
    text-align: left;

    > li {
        display: block;
        position: relative;
    }

    .nav-state:checked ~ & {
        display: block;
    }

    .nav-menu {
        z-index: 5;
        position: absolute;
        left: 0;
        display: none;
        top: 100%;
        overflow: auto;
        .material-shadow(1);
        // older ios devices don’t support calc with viewport units
        max-height: 100vh;
        max-height: calc(100vh - 4rem);

        &:hover {
            z-index: 6;
        }
    }

    .on-mobiles({
        display: block;

        .nav-menu {
            position: static;
            box-shadow: none;
            max-height: none;
            background-color: rgba(0, 0, 0, 0.025);
        }
    });
}

.nav-menu-main {
    .on-mobiles({
        position: fixed;
        left: 0;
        z-index: 1000;
        background-color: white;
        transform: translateX(-300px);
        width: 250px;
        transition: transform .2s ease-out;
        // older ios devices don’t support calc with viewport units
        height: 100vh;
        height: calc(100vh - 50px);
        overflow: auto;
        margin-top: 4px;
        box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;

        .nav-state:checked ~ & {
            transform: translateX(0);
            display: flex;
        }
    });
}

.nav-item {
    display: block;
    color: @color-text-default;
    font-family: @font-family-headline;
    text-decoration: none;
    padding: 0.725em 2em;
    cursor: pointer;
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
        background-color: @color-brand-primary;
        outline: none;
        color: white;
    }

    .on-mobiles({
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-top: 1em;
        padding-bottom: 1em;
    })
}

.nav-icon {
    display: inline-block;
    border-radius: 50%;
    width: 2.4em;
    height: 2.4em;
    line-height: 2.5em;
    background-color: @color-bg-default;
    border-bottom: none;
    padding: 0;
    font-size: 1.2em;

    &:hover,
    &:focus,
    .nav-state:checked ~ & {
        background-color: @color-bg-default;
        color: @color-text-default;
    }
}

.nav-item-mobile {
    display: none;

    .on-mobiles({
        display: inline-block;
    });
}

.nav-state {
    display: none;
}
