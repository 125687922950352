.material-shadow(@level: 1){
    & when (@level = 1) {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
    & when (@level = 2) {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    & when (@level = 3) {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    & when (@level = 4) {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    & when (@level = 5) {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
}

.on-handhelds(@rules) {
    @media (max-width: @screen-xs-max) {
        @rules();
    }
}

.on-tablets(@rules) {
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        @rules();
    }
}

.on-small-tablets(@rules) {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        @rules();
    }
}

.on-large-tables(@rules) {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        @rules();
    }
}

.on-desktops(@rules) {
    @media (min-width: @screen-lg-min) {
        @rules();
    }
}

.on-page-break(@rules) {
    @media(max-width: 980px) {
        @rules();
    }
}

// keep in sync with events.js on_mobiles
.on-mobiles(@rules) {
    @media (max-width: @screen-md-min) {
        @rules();
    }
}
