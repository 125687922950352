.social {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1.825em;
}

.social-links {
    font-size: 2rem;

    .on-mobiles({
        margin-top: auto;
        padding: 1rem 0;
    });

    &.is-nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        justify-content: space-evenly;
    }

    &.is-left {
        width: 4rem;
        position: absolute;
        right: 100%;
        margin-top: 3rem;

        @media (max-width: 1119px) {
            display: none;
        }
    }

    &.is-bottom {
        display: flex;
        flex-wrap: wrap;

        > a {
            margin-bottom: 0;
        }

        > a:not(:last-child) {
            margin-right: 1rem;
        }
    }
}

.social-services {
    margin: 0;
    padding: 0;
    text-align: center;

    > li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0.5em;
        margin-bottom: 1em;
    }
}

.social-service {
    white-space: normal;
    text-align: left;
}
