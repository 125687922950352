.form {
    input[type="text"],
    input[type="email"],
    input[type="search"],
    textarea,
    select {
        width: 100%;
        padding: 0.425em 0.825em;
        border: 2px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 2px;
        font-family: @font-family-headline;
        line-height: 1.2;
        transition: border-color .2s;
        background-color: white;

        &:focus {
            border-color: @color-brand-primary;
            outline: none;
        }
    }

    .js & input[type="search"] {
        appearance: none;
        border-radius: 20px;
    }

    fieldset {
        display: block;
        padding: 0;
        margin: 0 0 1em 0;
        border: none;
        font-family: @font-family-headline;


        legend {
            display: none;
        }
    }

    p + &,
    ul + &,
    ol + & {
        margin-top: 1.5em;
    }

    .content-body &:first-child {
        margin-top: -1em;
    }
}

.form-newsletter {
    text-align: center;
    margin-bottom: .5rem;
}

.form-search {
    position: relative;
}

.form-search-wrap {
    position: relative;

    + .form-search-submit {
        margin-top: 1em;
    }

    .fa-search {
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.form-filter {
    font-family: @font-family-headline;
    letter-spacing: 0;

    label,
    select {
        display: inline;
        padding: 0;
        margin: 0;
    }

    select {
        text-overflow: ellipsis;
        overflow: hidden;
        border: none;
        border-radius: 0;
        width: auto;
        color: @color-brand-primary;
        border-bottom: 1px dashed @color-brand-primary;
        appearance: none;
        text-align-last: center;
        max-width: 0;
        min-width: 130px;
        transition: max-width .2s;
        white-space: nowrap;
    }

    .filter-selector {
        line-height: 2;
    }

    option {
        color: @color-text-default;
    }

    span {
        white-space: pre;
    }

    .btn {
        margin-top: .3em;
    }
}

.form-help {
    padding: 1.5rem;
    box-sizing: border-box;

    [data-entity-reference] {
        display: none;
    }

    .control-group {
        border: none;
        padding: 0;
        margin: 0;
    }

    textarea {
        min-height: 120px;
    }

    figure {
        margin: 0;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    .btn-toolbar {
        margin-top: 1rem;
        .btn { margin-left: 1rem; &:first-child { margin-left: 0; } }
    }
}

.form-help-remarks {
    background-color: @color-bg-default;
    border-radius: 2px;
    padding: 1rem;
    margin-bottom: 2rem;

    ul {
        margin: 0;
        padding: 0 0 0 1.5rem;
    }

    blockquote {
        &:extend(.content-alone blockquote all);
        margin: 0 0 0 1rem;
    }
}

.form-help-overlay {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    position: absolute;
    left: calc(50% - (960px / 2));
    transform: none;
    z-index: 9999999999;
    width: 960px;
    background-color: white;
    border-radius: 2px;
    opacity: 1;
}

.form-help-hidden {
    display: none !important;
}

.form-help-container {
    margin-top: 1.825em;
}

.form-help-intro {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: @color-bg-default;

    p {
        margin: 0;
        margin-right: 1rem;
    }
}
