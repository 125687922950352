.alert {
    padding: 0.5em .75em;
    border-radius: 2px;
    text-align: center;

    .meta-box & {
        text-align: center;
        margin: .575em 0;
    }

    .fa {
        display: block;
        text-align: center;
        font-size: 2em;
        margin-bottom: .1em;
    }
}

.alert-success {
    background-color: lighten(@brand-success, 40%);
    color: darken(@brand-success, 20%);
}

.alert-error {
    background-color: lighten(@brand-danger, 40%);
    color: darken(@brand-danger, 20%);
}
