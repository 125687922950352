// unfortunately wkhtmltopdf still (last checked 12/2018) doesn’t support flexbox.
// we’ll need some hacky css table-layout help for the print styles

@preview-image-width: 150px;

.preview {
    display: flex;
    align-items: flex-start;

    @media print {
        display: table-row;
    }
}

.preview-vertical {
    display: block;
}

.preview-body {
    flex: 1;

    @media print {
        display: table-cell;
        vertical-align: top;
    }
}

.preview-image {
    margin: 0 1em 0 0;
    position: relative;
    overflow: hidden;
    width: @preview-image-width;
    max-width: @preview-image-width;

    img {
        width: 100%;
        display: block;
    }

    .preview-vertical & {
        @max-height: 250px;

        margin: 0 0 1em 0;
        max-height: @max-height;
        width: auto;
        max-width: none;

        img {
            max-height: @max-height;
        }
    }

    &:first-child:last-child {
        margin: 0;
    }

    @media print {
        display: table-cell;
        padding-right: 1em;
    }
}

.preview-image-copyright {
    position: absolute;
    left: 0;
    font-size: 0.6em;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    padding: 3px 4px;
    color: white;
    letter-spacing: 0;
    white-space: nowrap;
    font-family: @font-family-headline;
}

.preview-text {
    font-style: italic;
    margin-top: 1em;

    &:first-child:last-child {
        margin-top: 0;
    }

    .preview-text-only & {
        font-style: normal;
    }
}

.preview-data {
    margin: 0;
    line-height: 1.2;

    dt, dd {
        margin: 0;
    }
}

.preview-data-lvl-1 {
    font-size: 1.2em;
    font-weight: 700;
}

.preview-data-lvl-2 {
    font-size: 1.2em;
}

.preview-data-lvl-3 {
    font-size: 1.1em;
}
