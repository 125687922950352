.epitaph {
    background-color: @color-bg-default;
    padding: 1rem 2rem;
    box-sizing: border-box;
    margin-bottom: 2rem;
    border-radius: 2px;

    .teaser-header h4 {
        margin-top: 0;
    }

    .teaser-footer {
        display: none;

    }
}

.epitaph-heart {
    background-image: url(../img/broken_heart.svg);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: top right -2em;
}
