:root {
    font-weight: 400;
    font-size: @font-size-default;
    line-height: @font-lheight-default;
    color: @color-text-default;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    font-family: @font-family-default;

    background: @color-bg-default;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .on-mobiles({
        font-size: 90%;
    });
}

body {
    padding-bottom: 200px;
}

h1, h2, h3, h4, h5 {
    font-family: @font-family-headline;
}

a {
    color: @color-brand-primary;
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
    cursor: pointer;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

abbr[title] {
    font-variant: normal;
    border-bottom: none;
}

img[src] {
    position: relative;
    max-width: 100%;

    &:after {
        content: "Ohne Bild";
        display: inline-block;
        position: absolute;
        left: 0; right: 0; top: 0;
        padding: 2em 0.5em;
        font-family: @font-family-headline;
        box-sizing: border-box;
        text-align: center;
        background-color: @color-bg-default;
        z-index: 1;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
        background-color: white;
    }
}
