.quicknav {
    padding: .625em 0;
    margin: 0;

    li {
        display: block;
    }

    a {
        padding: .625em 1rem;
        margin: 0 ;
        display: block;
        text-decoration: none;
        line-height: 1;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover,
        &:focus {
            outline: none;
            background-color: @color-brand-primary;
            color: white;
        }

        &.quicknav-current {
            background-color: @color-bg-default;
            color: @color-text-default;
        }
    }
}

[data-quicknav-target] {
    padding-top: 25px;
    margin-top: -25px;
}
